import { ALLOWED_ROUTE_PARAMS, ALLOWED_URL_PARAMS, URL_PARAMS_MAP } from "@constants/mixpanel";
import { Carrier } from "@interfaces/Carrier";
import { MedicareApplication } from "@interfaces/MedicareApplication";
import { decamelize } from "humps";

export type RouteParamsArray = Array<[string, string | undefined]>

// Construct an object of carrier-related params to log to Mixpanel.
export const getCarrierMixpanelParams = (carrier?: Carrier): object => {
  return carrier ? {
    carrier_connecture_id: carrier.connectureId,
    carrier_name: carrier.name,
    carrier_slug: carrier.slug,
  } : {};
}

// Construct an object of default params to log to Mixpanel, derived from the route and URL params.
// Route params are camelized, whereas URL params are not.
export const getDefaultMixpanelParams = (routeParams: RouteParamsArray, urlParams: URLSearchParams): object => {
  const paramsForMixpanel: any = {};

  routeParams.reduce((map, [key, value]) => {
    if (ALLOWED_ROUTE_PARAMS.includes(key)) {
      map[decamelize(key)] = value
    }
    return map
  }, paramsForMixpanel);

  urlParams.forEach((value, key) => {
    if (ALLOWED_URL_PARAMS.includes(key)) {
      const formattedKey = URL_PARAMS_MAP[key as keyof typeof URL_PARAMS_MAP]
      paramsForMixpanel[formattedKey || key] = value
    }
  })

  return paramsForMixpanel;
}

// Create a normalized page name from the path name by replacing any route params values with their keys.
// e.g. "agent-name" is replaced with "agentSlug"
export const getNormalizedPageName = (routeParams: RouteParamsArray, pathName: string = ""): string => {
  return routeParams.reduce((path, [key, value]) => {
    return value ? path.replace(value, `:${key}`) : path;
  }, pathName);
}

// Construct an object of medicare application-related params to log to Mixpanel.
export const getMedicareApplicationParams = (application: MedicareApplication): object => {
  return {
    cms_plan_id: application.cmsPlanId,
    effective_date: application.effectiveDate,
    fips_code: application.fipsCode,
    medicare_application_id: application.id,
    medicare_application_status: application.status,
    plan_name: application.planName,
    plan_sub_type: application.planSubType,
    plan_type: application.planType,
    state: application.state,
    year: application.planYear,
    zip_code: application.zipCode,
    ...getCarrierMixpanelParams(application.carrier),
  }
}