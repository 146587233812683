import { checkboxClasses, svgIconClasses, Theme } from '@mui/material';
import { Components } from '@mui/material/styles/components';

export const MuiCheckbox: Components<Theme>['MuiCheckbox'] = {
  styleOverrides: {
    root: ({ theme }) => ({
      color: theme.palette.grey[500],
      padding: 5,
      [`&.${checkboxClasses.sizeMedium} .${svgIconClasses.root}`]: {
        fontSize: 22,
      },
      [`&.${checkboxClasses.disabled} .${svgIconClasses.root}`]: {
        color: theme.palette.grey[500],
      },
      [`&.${checkboxClasses.disabled}::before`]: {
        backgroundColor: theme.palette.grey[200],
      },
    }),
  },
};
