declare global {
  interface Window {
    MIXPANEL_ENVIRONMENT: string;
  }
}

export const MIXPANEL_ENVIRONMENT = window.MIXPANEL_ENVIRONMENT;

export const MIXPANEL_TOKEN = MIXPANEL_ENVIRONMENT === 'production'
  ? '1be363659bb1916d0fc8f5e5986246ba'
  : '4f044e73c8f230dbabce76c9217f73ca'

export const ALLOWED_ROUTE_PARAMS = [
  'agentSlug',
  'contactSlug',
  'planId',
  'soaId',
  'userSlug',
]

export const ALLOWED_URL_PARAMS = [
  'carrier_id',
  'cms_plan_id',
  'effective_date',
  'fips_code',
  'plan_id',
  'plan_name',
  'plan_sub_type',
  'plan_type',
  'state',
  'year',
  'zip_code',
]

// We rename certain url params
export const URL_PARAMS_MAP = {
  'carrier_id': 'carrier_connecture_id'
}