import { camelizeKeys } from 'humps';

declare global {
  interface Window {
    __featureFlags__: {
      mixpanelEnabled: boolean;
      enrollmentRedirectLinkEnabled: boolean;
      recaptchaEnabled: boolean;
    };
  }
}

function cloneDeepIfAvailable<T>(objectToClone: T): T {
  if (!objectToClone) {
    return {} as T;
  }
  return typeof structuredClone === 'function' ? structuredClone(objectToClone) : objectToClone;
}

// Deep Clone the config so people can't use the console to update and toggle configs
// eslint-disable-next-line no-underscore-dangle
export const FEATURE_FLAGS = cloneDeepIfAvailable(
  camelizeKeys(window.__featureFlags__ || {}),
) as Window['__featureFlags__'];
